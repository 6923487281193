import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VTextField,{attrs:{"outlined":"","rounded":"","label":"Search Users..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VBtn,{staticClass:"ml-2",attrs:{"rounded":"","height":"56","elevation":"0","dark":""},on:{"click":function($event){_vm.addUserDialog = true; _vm.edituser = null; _vm.isEdit = false}}},[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v(" Add User ")],1)],1),_c(VCard,{attrs:{"outlined":"","loading":_vm.loading}},[_c(VDataTable,{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.users},scopedSlots:_vm._u([{key:"item.actions",fn:function({ item }){return [_c(VBtn,{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.editUserDetails(item)}}},[_c(VIcon,[_vm._v("mdi-pencil")])],1),(item.id != 1 && item.role == 'admin')?_c(VBtn,{attrs:{"color":"warning","to":{ name: 'Side Bar Access Control', params: { userId: item.id } },"icon":""}},[_c(VIcon,[_vm._v("mdi-security")])],1):_vm._e(),(item.id != 1)?_c(VBtn,{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.deleteUser(item)}}},[_c(VIcon,[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"item.role",fn:function({ item }){return [_c(VChip,{attrs:{"color":item.role === 'admin' ? 'red' : 'green',"dark":""}},[_vm._v(" "+_vm._s(item.role === 'admin' ? 'Admin' : 'Moderator')+" ")])]}}])})],1),(_vm.addUserDialog)?_c(VDialog,{attrs:{"max-width":"768","persistent":""},model:{value:(_vm.addUserDialog),callback:function ($$v) {_vm.addUserDialog=$$v},expression:"addUserDialog"}},[_c('AddUserDialog',{attrs:{"user":_vm.edituser,"edit":_vm.isEdit},on:{"close":_vm.closeDialogs}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }